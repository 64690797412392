<template>
  <KycKybList />
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import KycKybList from "@/components/list/KycKybList.vue";

export default {
  name: "KycKybRequests",
  components: { KycKybList },
  computed: {},
  data() {
    return {};
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t("ROUTES.KYC_KYB_REQUESTS") }
    ]);
  }
};
</script>
