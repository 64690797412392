<template>
  <div>
    <div class="card">
      <div class="card-body">
        <b-table
          ref="listTable"
          id="list-table"
          primary-key="id"
          :items="fetchItems"
          :fields="fields"
          responsive="sm"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-5">
              <b-spinner class="align-middle busy-spinner"></b-spinner>
            </div>
          </template>
          <!-- <template #cell(documentState)="data">
            <span class="form-control-plaintext text-success" v-if="data.value">
              {{ $t("kyc_kyb.approved") }}
              <b-icon icon="check" variant="success"></b-icon>
            </span>
            <span class="form-control-plaintext text-danger" v-else>
              {{ $t("kyc_kyb.denied") }}
              <b-icon icon="x" variant="danger"></b-icon>
            </span>
          </template> -->
          <template #cell(buttons)="row">
            <button
              class="list-button"
              @click.stop.prevent="showDetailsModal(row, 'totals')"
            >
              <i class="flaticon2-document text-info"></i>
            </button>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      ref="kyc-details-modal"
      hide-footer
      :title="modalTitle"
      hide-header
      v-model="detailsModal"
      no-close-on-backdrop
      size="lg"
      scrollable
    >
      <b-overlay
        :show="kycKybDetailsLoading || isSubmitting"
        rounded="sm"
        :opacity="1"
      >
        <div
          class="row justify-content-between custom-modal-header border-bottom mb-5"
        >
          <div class="row">
            <span class="custom-modal-title">
              {{ modalTitle }}
              <span
                v-if="kycKybDetails"
                :class="`text-${getStatusVariantIcon.variant}`"
                >({{ getStatusVariantIcon.status
                }}<b-icon
                  :icon="getStatusVariantIcon.icon"
                  font-scale="1.1"
                  :variant="getStatusVariantIcon.variant"
                ></b-icon
                >)</span
              >
            </span>
          </div>
          <div>
            <i
              class="fa fa-times custom-modal-close"
              @click="detailsModal = false"
            ></i>
          </div>
        </div>
        <div class="d-block text-center" v-if="!kycKybDetails">
          <b-alert show>{{ $t("COMMON.NO_DATA_AVAILABLE") }}</b-alert>
        </div>
        <template v-else>
          <div class="d-flex flex-column mb-3 border-bottom">
            <div class="row justify-content-between align-items-center">
              <label class="col">{{ $t("FORM.FIRST_NAME") }}</label>
              <label class="col text-center">{{ $t("FORM.LAST_NAME") }}</label>
              <label class="col text-center">{{ $t("FORM.EMAIL") }}</label>
              <label class="col text-right"></label>
            </div>
            <div
              class="row justify-content-between align-items-center mb-3"
              v-for="(item, index) in namesSurnamsEmails"
              :key="`names-surnames-emails-${index}`"
            >
              <span class="col">
                {{ item.firstName }}
              </span>
              <span class="col text-center">
                {{ item.lastName }}
              </span>
              <span class="col text-center">
                {{ item.email }}
              </span>
              <span class="col text-right">
                <b-button
                  size="sm"
                  variant="info"
                  :disabled="isSubmitting"
                  @click="approveNameSurnamEmail(item)"
                  >{{
                    `${$t("COMMON.inspect")}${isSubmitting ? "..." : ""}`
                  }}</b-button
                >
              </span>
            </div>
          </div>
          <div class="px-4 mb-3 border-bottom">
            <div class="row justify-content-between align-items-center">
              <label class="col-form-label">{{
                $t("kyc_kyb.taxNumber")
              }}</label>
              <div class="">
                <span class="form-control-plaintext">
                  {{ kycKybDetails.taxNumber || "" }}
                </span>
              </div>
            </div>
          </div>

          <div class="px-4 mb-3 border-bottom">
            <div
              class="row justify-content-between align-items-center"
              v-for="(commerceNum,
              commerceNumIDX) in kycKybDetails.commerceNumber"
              :key="`commerceNum-${commerceNumIDX}`"
            >
              <label class="col-form-label">{{
                $t("kyc_kyb.commerceNumber")
              }}</label>
              <div class="">
                <span class="form-control-plaintext">
                  {{ commerceNum }}
                </span>
              </div>
            </div>
          </div>

          <div class="px-4 mb-3">
            <FileDisplayList
              :files="[kycKybDetails.ubo]"
              :label="$t('kyc_kyb.files_ubo')"
              @onShowFile="showFile($event)"
            />
          </div>

          <div class="px-4 mb-3">
            <FileDisplayList
              :files="kycKybDetails.idCard"
              :label="$t('kyc_kyb.idCard')"
              @onShowFile="showFile($event)"
            />
          </div>

          <div class="px-4 mb-3">
            <FileDisplayList
              :files="[kycKybDetails.companyActivities]"
              :label="$t('kyc_kyb.files_companyActivities')"
              @onShowFile="showFile($event)"
            />
          </div>

          <div class="px-4 mb-3">
            <div
              class="row justify-content-between align-items-center"
              v-if="!kycKybDetails.sourceOfFund"
            >
              <label class="col-form-label">{{
                $t("kyc_kyb.sourceOfFund")
              }}</label>
              <div>
                <span class="form-control-plaintext">
                  <b-icon icon="x" font-scale="1.5" variant="danger"></b-icon>
                </span>
              </div>
            </div>
            <div v-else>
              <FileDisplayList
                :files="kycKybDetails.sourceOfFundFile"
                :label="$t('kyc_kyb.sourceOfFund')"
                @onShowFile="showFile($event)"
              />
            </div>
          </div>

          <div class="px-4 mb-3">
            <FileDisplayList
              :files="kycKybDetails.orgChart"
              :label="$t('kyc_kyb.files_orgChart')"
              @onShowFile="showFile($event)"
            />
          </div>
        </template>
        <div
          class="row justify-content-between align-items-center px-4 pt-5 border-top"
        >
          <b-button variant="secondary" @click="detailsModal = false">{{
            $t("FORM.CANCEL")
          }}</b-button>
          <div class="d-flex flex-wrap" v-if="kycKybDetails">
            <template v-if="isPendingOperationApproval">
              <b-button
                variant="danger"
                class="mr-2"
                @click="onUpdateDocumentState('reject')"
                >{{ $t("COMMON.reject") }}</b-button
              >
              <b-button
                variant="success"
                @click="onUpdateDocumentState('approve')"
                >{{ $t("FORM.CONFIRM") }}</b-button
              >
            </template>
            <template v-else>
              <b-button
                variant="warning"
                v-if="isUserDocumentStateApproved"
                @click="onUpdateDocumentState('reject')"
                >{{ $t("kyc_kyb.revoke_confirmation") }}</b-button
              >
              <b-button
                v-else
                variant="success"
                @click="onUpdateDocumentState('approve')"
                >{{ $t("FORM.CONFIRM") }}</b-button
              >
            </template>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Api from "@/services/api";
import FileDisplayList from "../FileDisplayList.vue";
// import { getMimeTypeFromBase64 } from "@/utils/get-mime-type";
import { downloadUrl } from "@/utils/download-file";

export default {
  components: { FileDisplayList },
  name: "KycKybList",
  props: [],
  data() {
    return {
      isBusy: false,
      detailsModal: false,
      isSubmitting: false,
      modalTitle: "",
      selectedCompanyID: "",
      fields: [
        {
          key: "id",
          label: "ID"
        },
        {
          key: "title",
          label: this.$t("COMPANY.TITLE")
        },
        {
          key: "parentTitle",
          label: this.$t("COMPANY.parentTitle")
        },
        {
          key: "email",
          label: this.$t("FORM.EMAIL")
        },
        {
          key: "companyRank",
          label: this.$t("LIST.LEVEL")
        },
        /* {
          key: "documentState",
          label: this.$t("kyc_kyb.status")
        }, */
        {
          key: "buttons",
          label: this.$t("kyc_kyb.kyc_kyc_details"),
          class: "text-center"
        }
      ],
      selectedCompanyDocumentId: null
    };
  },
  computed: {
    ...mapState({
      kycKybDetailsLoading: state => state.company.kycKybDetailsLoading,
      kycKybDetails: state => state.company.kycKybDetails,
      kycFilesStatus: state => state.company.kycFilesStatus
      // documentStateSelects: state => state.company.documentStateSelects
    }),
    namesSurnamsEmails() {
      return this.kycKybDetails?.names || [];
    },
    isUserDocumentStateApproved() {
      return this.kycKybDetails?.documentState || false;
    },
    fetchUrl() {
      return "companies";
    },
    isPendingOperationApproval() {
      return this.kycFilesStatus === "pending";
    },
    getStatusVariantIcon() {
      let status = null,
        variant = "",
        icon = "soundwave";
      if (this.isPendingOperationApproval) {
        status = this.$t("kyc_kyb.waiting");
        variant = "warning";
        icon = "soundwave";
      } else {
        if (this.isUserDocumentStateApproved) {
          status = this.$t("kyc_kyb.approved");
          variant = "success";
          icon = "check";
        } else {
          status = this.$t("kyc_kyb.denied");
          variant = "danger";
          icon = "x";
        }
      }
      return { status, variant, icon };
    }
  },
  methods: {
    async fetchItems() {
      this.isBusy = true;
      try {
        const res = await Api.get("get-all-document-file-list");
        this.isBusy = false;
        if (res.data.length > 0) {
          return res.data.map(val => ({
            ...val,
            companyRank: val.roles[0].replace("ROLE_", "")
          }));
        }
        return [];
      } catch (error) {
        this.isBusy = false;
      }
    },
    async showDetailsModal(row) {
      const id = row.item?.companyId;
      this.detailsModal = !this.detailsModal;
      this.modalTitle = row.item?.title || "";
      this.selectedCompanyID = id;
      this.selectedCompanyDocumentId = row.item?.id;
      await this.$store.dispatch("company/GET_KYC_KYB", id);
    },
    showFile(url) {
      if (url) {
        downloadUrl(url);
      }
    },
    async onUpdateDocumentState(type = "approve") {
      /* const states = [
        {
          id: 1,
          name: "waiting"
        },
        {
          id: 2,
          name: "uploaded"
        },
        {
          id: 3,
          name: "approved"
        },
        {
          id: 4,
          name: "denied"
        }
      ]; */
      this.isSubmitting = true;
      try {
        Promise.all([
          await this.$store.dispatch("company/UPDATE_DOCUMENT_STATE", {
            value: type === "approve" ? 3 : 4,
            companyId: this.selectedCompanyID
          }),
          await this.$store.dispatch("company/UPDATE_USER_DOCUMENT_STATE", {
            companyId: this.selectedCompanyID,
            documentState: type === "approve" ? true : false
          }),
          await this.$store.dispatch(
            "company/GET_KYC_KYB",
            this.selectedCompanyID
          )
        ]);
        this.isSubmitting = false;

        this.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
      } catch (error) {
        this.isSubmitting = false;

        this.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    async approveNameSurnamEmail(item) {
      const formData = {
        companyId: this.selectedCompanyID,
        email: item.email,
        name: item.firstName,
        surname: item.lastName,
        companyDocumentId: this.selectedCompanyDocumentId
      };
      try {
        this.$store.dispatch("company/ONFIDO_CHECK", formData);
        this.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
      } catch (error) {
        this.$bvToast.toast(
          error?.response?.data?.message || this.$t("FORM.ERROR"),
          {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          }
        );
      }
    }
  },
  created() {
    // this.$store.dispatch("company/GET_DOCUMENT_STATE_DETAIL_LIST");
  }
};
</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid rgb(230, 221, 221) !important;
}
.border-top {
  border-top: 1px solid rgb(230, 221, 221) !important;
}
.table th,
.table td {
  border: 1px !important;
  padding: 0.5rem 0 0.5rem 0;
}
label {
  font-weight: bold;
}

.custom-modal-header {
  height: 70px;
  padding: 1rem 1.75rem;
}
.custom-modal-title {
  font-weight: 500;
  font-size: 1.3rem;
  color: #181c32;
}

.custom-modal-close:hover {
  color: #181c32;
  cursor: pointer;
}
</style>
