<template>
  <div>
    <div
      class="row justify-content-between align-items-center"
      v-for="(file, fileIDX) in files"
      :key="`file-${fileIDX}`"
    >
      <label class="col-form-label col-10 pl-0">{{ label }}</label>
      <b-button variant="link" size="sm" class="pr-0" @click="showFile(file)">{{
        $t("COMMON.show_file")
      }}</b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "FileDisplayList",
  props: {
    files: Array,
    label: String
  },
  methods: {
    showFile(doc) {
      this.$emit("onShowFile", doc);
    }
  }
};
</script>
<style scoped>
label {
  font-weight: bold;
}
</style>
